import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Menu, X } from "lucide-react";
import Slider from "react-infinite-logo-slider";
import teamImage from "../../assets/coopvest_team_2.jpg";
import sideImage1 from "../../assets/coopvest_team_5.jpg";
import sideImage2 from "../../assets/coopvest_team_9.jpg";
import sideImage3 from "../../assets/coopvest_team_11.jpg";
import sideImage4 from "../../assets/coopvest_team_12.jpg";
import sideImage5 from "../../assets/coopvest_team_2.jpg";
import member1 from "../../assets/coopvest_team_1.jpg";
import member2 from "../../assets/coopvest_team_2.jpg";
import member3 from "../../assets/coopvest_team_3.jpg";
import member4 from "../../assets/coopvest_team_4.jpg";
import member5 from "../../assets/coopvest_team_5.jpg";
import member6 from "../../assets/coopvest_team_6.jpg";
import member7 from "../../assets/coopvest_team_7.jpg";
import member8 from "../../assets/coopvest_team_8.jpg";
import member9 from "../../assets/coopvest_team_9.jpg";
import member10 from "../../assets/coopvest_team_10.jpg";
import member11 from "../../assets/coopvest_team_11.jpg";
import member12 from "../../assets/coopvest_team_12.jpg";
import member13 from "../../assets/coopvest_team_13.jpg";
import member14 from "../../assets/coopvest_team_14.jpg";
import rentReliability from "../../assets/rentshield.png";
import rentEfficiency from "../../assets/rentefficiency.png";
import rentNewness from "../../assets/rentnewness.png";
import rentTenacity from "../../assets/renttenacity.png";

const values = [
  {
    image: rentReliability,
    name: "Reliability",
    description:
      "At Coopvest, we understand the importance of a dependable banking partner. We are committed to providing a secure and stable platform for your financial needs. You can trust us to safeguard your information and transactions, giving you peace of mind and the confidence to manage your money effectively.",
  },
  {
    image: rentEfficiency,
    name: "Efficiency",
    description:
      "We believe in making your financial life easier. Our user-friendly digital platform simplifies everyday banking tasks, allowing you to manage your finances effortlessly. Whether it's checking your balance, sending payments, or accessing account information, we empower you to get things done quickly and conveniently, freeing up your valuable time to focus on what matters most.",
  },
  {
    image: rentNewness,
    name: "Newness",
    description:
      "The world of finance is constantly evolving, and we is dedicated to staying ahead of the curve. We embrace innovation by continuously seeking new and improved solutions to meet your ever-changing financial needs. We are committed to providing you with the latest tools and services to empower your financial journey and equip you for success in the ever-changing economic landscape.",
  },
  {
    image: rentTenacity,
    name: "Tenacity",
    description:
      "Your financial well-being is our top priority. We are not just a bank; we are your partner in achieving your financial goals. We demonstrate unwavering commitment to your success by consistently exceeding your expectations and providing the support you need to navigate your financial journey with confidence. With us by your side, you can be sure of our unwavering dedication to helping you achieve your financial aspirations.",
  },
];

export default function About() {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <div className="bg-white">
      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-jakarta font-black tracking-tight text-primary sm:text-6xl">
                    WHO WE ARE
                  </h1>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  Coopvest, an investment company, duly registered on 21st of Novemeber, 2021 with the 
                  Corporate Affairs Commission (CAC) with registration number 1865655. It  has roots 
                  in the Cooperative Movement in Nigeria and Africa and it is seeking to expand its 
                  range of digital financial Services. We are dedicated to empowering cooperatives, Individuals, 
                  and Businesses, providing innovative and tailored digital financial services designed to meet 
                  the unique needs of individuals, businesses, and cooperative members.
                  What sets us apart is our unwavering dedication to customer satisfaction and our relentless 
                  pursuit of excellence in everything we do. Our focus is on core integrity and excellence, 
                  positioning us to lead the way in the digital banking revolution.
                  </p>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                    What sets us apart is our unwavering dedication to customer
                    satisfaction and our relentless pursuit of excellence in
                    everything we do. Our focus is on core integrity and
                    excellence, positioning us to lead the way in the digital
                    banking revolution.
                  </p>
                </div>
                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <img
                        src={sideImage2}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <img
                        src={member7}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 !object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src={member8}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  {/* <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                    <div className="relative">
                      <img
                        src={member10}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <img
                        src={member7}
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                      />
                      <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content section */}
        <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-jakarta font-semibold tracking-tight text-primary sm:text-4xl">
              Our mission
            </h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-600">
                  At Coopvest Limited, we are on a mission of evolving to
                  satisfy the financial wants and needs. Our commitment lies in
                  providing accessible, user-friendly, and secure fintech
                  solutions. By bridging the gap between traditional financial
                  services and digital innovation, we empower both individuals
                  and Cooperative societies to thrive financially. As we
                  persistently innovate and adapt our strategies and
                  technologies, our unwavering dedication to empowering our
                  community remains at the forefront.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Image section */}
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            src={teamImage}
            alt=""
            className="h-auto w-full object-contain xl:rounded-3xl"
          />
        </div>

        {/* Values section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-jakarta font-semibold tracking-tight text-primary sm:text-4xl">
              Our vision
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Coopvest Limited is an investment company seeking to expand into
              the fintech space. Our team of experts brings together a wealth of
              experience in finance and technology, working tirelessly to create
              innovative solutions that cater to your unique needs.
            </p>
          </div>
          <aside className="mx-auto !max-w-2xl lg:mx-0 mt-16">
            <h2 className="text-3xl max-w-2xl font-jakarta font-semibold tracking-tight text-primary sm:text-4xl">
              Our values
            </h2>
            <dl className="mx-auto mt-6 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              {values.map((value) => (
                <div key={value.name} className="p-5 rounded-3xl bg-primary/40">
                  <img
                    src={value.image}
                    alt=""
                    className="h-[100px] w-[100px] object-contain"
                  />
                  <dt className="font-jakarta font-semibold text-gray-900">
                    {value.name}
                  </dt>
                  <dd className="mt-1 text-gray-600">{value.description}</dd>
                </div>
              ))}
            </dl>
          </aside>
        </div>

        {/* Logo cloud */}
        <div className="relative isolate -z-10 mt-32 sm:mt-48">
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg
              className="h-[40rem] w-[80rem] flex-none stroke-gray-200"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  x="50%"
                  y="50%"
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                <path
                  d="M-300 0h201v201h-201Z M300 200h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
              />
            </svg>
          </div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
              Businesses that count on us
            </h2>
            <Slider blurBorders={true} duration={20}>
              {/* <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5"> */}
              <Slider.Slide>
                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="/logo1.png"
                  alt="Transistor"
                  width={158}
                  height={48}
                />
              </Slider.Slide>

              <Slider.Slide>
                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="/logo2.png"
                  alt="Reform"
                  width={158}
                  height={48}
                />
              </Slider.Slide>
              <Slider.Slide>
                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                  src="/logo3.png"
                  alt="Tuple"
                  width={158}
                  height={48}
                />
              </Slider.Slide>
              <Slider.Slide>
                <img
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 bg-black"
                  src="/logo-horizon.png"
                  alt="Tuple"
                  width={158}
                  height={48}
                />
              </Slider.Slide>
              {/* </div> */}
            </Slider>
          </div>
        </div>

        {/* Team section */}
      </main>

      {/* Footer */}
    </div>
  );
}
