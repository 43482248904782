import member1 from "../../assets/coopvest_team_1.jpg";
import member2 from "../../assets/coopvest_team_2.jpg";
import member3 from "../../assets/coopvest_team_3.jpg";
import member4 from "../../assets/coopvest_team_4.jpg";
import member5 from "../../assets/coopvest_team_5.jpg";
import member6 from "../../assets/coopvest_team_6.jpg";
import member7 from "../../assets/coopvest_team_7.jpg";
import member8 from "../../assets/coopvest_team_8.jpg";
import member9 from "../../assets/coopvest_team_9.jpg";
import member10 from "../../assets/coopvest_team_10.jpg";
import member11 from "../../assets/coopvest_team_11.jpg";
import member12 from "../../assets/coopvest_team_12.jpg";
import chris from "../../assets/chris.jpg";
import member14 from "../../assets/coopvest_team_14.jpg";
import member16 from "../../assets/coopvest_team_16.jpg";
import member15 from "../../assets/coopvest_team_15.png";
import { useEffect, useState } from "react";
import { DrawerDialogDemo } from "../../components/DrawerDialog";
const people = [
  {
    name: "Stella Abu",
    role: "Managing Director COOPVEST",
    imageUrl: member4,
    bio: `Stella Abu is a seasoned professional with over 8 years of expertise in Product, Project, and operational excellence within the Fintech Industry. 
      Leveraging her extensive experience, Stella has spearheaded numerous successful projects, including the implementation of agency banking solutions and the launch of innovative products.
      She has effectively managed strategic partnerships and led product development initiatives aimed at expanding market reach and driving growth in rural areas.
      Stella holds a Master's degree in Philosophy and possesses certifications, including Google Agile Project Management and Product Masterclass from Product School.
      Stella is also the co-founder of Product House Africa, where she plays a pivotal role in building a vibrant community for product managers in Africa and driving innovation in technology.
      Additionally, she co-founded Acruxbyte, a technology company committed to creating opportunities for entrepreneurs and making a positive impact in her community.`,
  },
  {
    name: "Mrs Iyanda",
    role: "Chief Administrative Officer",
    imageUrl: member16,
    // bio: "Quia illum aut in beatae. Possimus dolores aliquid accusantium aut in ut non assumenda. Enim iusto molestias aut deleniti eos aliquid magnam molestiae. At et non possimus ab. Magni labore molestiae nulla qui.",
  },
  {
    name: "ONIFADE, Pelumi",
    role: "Head of Operations",
    imageUrl: member6,
    bio: `Oluwapelumi Onifade is a result driven staff with a record in optimizing efficiency, process improvement, resource management and problem solving skills.
      She is a creative, analytic, and proactive team player with over seven(7) years experience in administrative, business strategy, and executive management. 
      She strategically manages the operational activities of the company towards excellence. 
      She holds a Bachelor’s degree from Adeleke University and is working on being a chartered accountant.`,
  },
  {
    name: "ADESINA, Anuoluwapo",
    role: "Adminstrative officer/ Customer Service officer",
    imageUrl: member10,
    bio: `
    Anuoluwapo Adesina is a passionate, performance-driven, and ambitious go-getter with a strong passion for customer satisfaction over 4 years and over a year in administrative skills. She is a dynamic professional with a unique blend of customer service and administrative skills. With a background in both areas, she brings a holistic approach to her work, ensuring that both internal and external stakeholders receive the highest level of support.
    She's an undergraduate in Sociology, In her role as an Administrative Officer, She has managed office operations and provided support to her colleagues. She is proficient in handling a variety of administrative tasks, including managing calendars, coordinating meetings, and maintaining records.
    In addition, Anuoluwapo is a highly skilled and experienced professional with a unique combination of customer service and administrative skills, making a positive impact in her fields.
    `,
  },
  {
    name: "OLOYEDE, Mannan",
    role: "Software Developer",
    imageUrl: member1,
    bio: `Mannan Oloyede is a driven Software Engineer with over 5 years of experience in crafting user-centric solutions. As one of the Lead Engineers at Coopvest Limited, Mannan focuses on the development of Coopmoni, an innovative mobile application. Leveraging his expertise in Java and Kotlin, he engineers features for financial transactions with a strong emphasis on security and reliability. Mannan collaborates seamlessly within cross-functional teams, ensuring alignment with business objectives and optimal application performance.
      Mannan's expertise extends to previous roles such as Technology Officer at Cooperative Mortgage Bank and Technology Strategist at New Horizons Nigeria. In these positions, he provided guidance in optimizing operational processes and implementing technology solutions.
      Throughout his career, Mannan has maintained a strong commitment to continuous learning and professional growth. By leveraging AI models in projects such as Face Mask Detection and developing mobile applications to aid smokers, he demonstrates his ability to harness technology for impactful outcomes.`,
  },
  {
    name: "Chris Abu",
    role: "IT Officer",
    imageUrl: chris,
     bio: `Chris Abu is a standout figure in the tech world, having led EdgeBase Technologies' engineering unit with exceptional skill. With over 10 years of experience, Chris has excelled in various areas of software engineering, from developing chatbots to creating immersive gaming experiences and intricate 3D models. 
     Additionally, Chris has served as a system administrator at Cooperative Mortgage Bank, showcasing versatility across different tech domains. Currently, Chris heads a top-notch software development team, showcasing mastery in Java, C#, and Python. Their unwavering determination and adaptability have not only propelled their own success but have also influenced numerous projects, establishing Chris as a true innovator in the constantly evolving tech industry.`,
  },
  // {
  //   name: "AGBETILE, Omobolanle A.",
  //   role: "Product Marketing Manager",
  //   imageUrl: member3,
  //   // bio: "Quia illum aut in beatae. Possimus dolores aliquid accusantium aut in ut non assumenda. Enim iusto molestias aut deleniti eos aliquid magnam molestiae. At et non possimus ab. Magni labore molestiae nulla qui.",
  // },

  {
    name: "OLASUPO, Taiwo",
    role: "Onboarding Officer",
    imageUrl: member14,
    bio: `
    Taiwo Olasupo is a dedicated professional in the banking and finance sector for over 2years and committed 
    Leveraging experience,Taiwo is expert in delivering exceptional service and financial solutions to customers and ensuring that every new user has a positive experience 
She is responsible for creating and maintaining documentations and training experience for agents and super agents 
    Taiwo hold a national diploma in banking and finance and possess certificate bring a year of hands on experience in delivering exceptional service and financial solutions to the customer's`,
  },
  {
    name: "Bamiduro, Daniel",
    role: "Communications Officer",
    imageUrl: member15,
  },

  // More people...
];

export default function Team() {
  const [open, setOpen] = useState(false);
  const [currentWord, setCurrentWord] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");

  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl capitalize font-jakarta font-black tracking-tight text-primary sm:text-4xl">
            Meet our team
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We’re a dynamic group of individuals who are passionate about what
            we do and dedicated to delivering the best results for our clients.
          </p>
        </div>
        <div className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-6  sm:grid-cols-2 lg:max-w-4xl justify-center">
          {people.map((person) => (
            <div key={person.name} className="">
              <img
                className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                src={person.imageUrl}
                alt=""
              />
              <div className="flex-auto !w-fit mt-3">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">
                  {person.name}
                </h3>
                <p className="text-base leading-7 text-gray-600">
                  {person.role}
                </p>

                {/* <p
                  className="mt-6 text-base leading-7 text-gray-600"
                  onClick={() => {
                    setCurrentTitle(person.name);
                    setCurrentWord(person.bio!!);
                  }}
                >
                  {person.bio?.substring(0, 200)}...{" "}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => setOpen(true)}
                  >
                    read more
                  </span>
                </p> */}
              </div>
            </div>
          ))}
          {/* <DrawerDialogDemo
            open={open}
            setOpen={setOpen}
            word={currentWord}
            title={currentTitle}
          /> */}
        </div>
      </div>
    </div>
  );
}
