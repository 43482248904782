import { useEffect, useState } from "react";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { BarChart, X } from "lucide-react";
import { Button } from "../../components/ui/button";
import { Link } from "react-router-dom";
import Hero from "../../components/home/Hero";
import GetStarted from "../../components/home/GetStarted";
import DownloadApp from "../../components/home/DownloadApp";
import MoreDetails from "../../components/home/MoreDetails";
import CallToAction from "../../components/home/CallToAction";

const navigation = [
  { name: "Product", href: "#" },
  { name: "Features", href: "#" },
  { name: "Marketplace", href: "#" },
  { name: "Company", href: "#" },
];

export default function Home() {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <main className="relative bg-whte bg-[#f2f7f8]">
      <section className="">
        <Hero />
      </section>
      <section id="get-started" className="px-4 lg:px-6 xl:px-20 lg:mt-10">
        <GetStarted />
      </section>
      <section className="bg-primary mt-10 lg:mt-24">
        <CallToAction />
      </section>
      <section className="!mt-16 w-full !mb-16">
        <MoreDetails />
      </section>
    </main>
  );
}
