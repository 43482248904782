import "./App.css";
import Layout from "./layout/layout";
import CustomRoutes from "./router";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <Layout>
        <CustomRoutes />
      </Layout>
    </Router>
  );
}

export default App;
