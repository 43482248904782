import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const Privacy = (props: Props) => {
  return (
    <main className="container mx-auto px-5">
      <p className="uppercase font-jakarta font-black text-3xl lg:text-5xl my-11 text-[#034f36] text-center ">
        Privacy
      </p>
      <article>
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          Background
        </h1>
        <div className="space-y-3">
          <p>
            At CoopVest, we understand the importance of privacy and security
            when it comes to handling sensitive financial information. Our
            mission is to provide users with a convenient and secure platform
            for managing their finances, conducting transactions, and accessing
            a wide range of financial services.
          </p>
          <p>
            In today's digital age, where online transactions and mobile banking
            have become commonplace, safeguarding customer information is
            paramount. We recognize the trust placed in us by our users and are
            committed to maintaining the highest standards of data protection
            and privacy.
          </p>
          <p>
            Our Privacy Policy outlines the types of information we collect, how
            we collect it, and the purposes for which it is used. We collect
            various types of information, including personal, financial, device,
            and usage data, to provide and improve our services, verify user
            identities, prevent fraud, and comply with legal obligations.
          </p>
          <p>
            We employ advanced security measures and encryption protocols to
            safeguard user data and ensure the confidentiality and integrity of
            information transmitted through our platform. Additionally, we
            regularly update our systems and practices to adapt to evolving
            threats and regulatory requirements.
          </p>
          <p>
            Transparency and accountability are core principles of our privacy
            practices. We are committed to providing users with clear and
            understandable information about our data collection and usage
            practices, as well as their rights and options regarding their
            personal information.
          </p>
          <p>
            By adhering to strict privacy standards and empowering users with
            control over their data, we aim to foster trust and confidence in
            our platform. We continuously strive to uphold the highest standards
            of privacy and security to protect the interests of our users and
            maintain their trust in CoopVest.
          </p>
        </div>
        <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      </article>
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          1. Types of Information Collected
        </h1>
        <div className="space-y-3 px-6">
          <p>
            We collect various types of information to provide and improve our
            services to you. This may include:
          </p>
          <ul className=" space-y-2">
            <li className="list-disc">
              Personal Information: Such as your name, email address, phone
              number, and other contact information provided during account
              registration or profile setup.
            </li>
            <li className=" !list-disc">
              Financial Information: Including bank account details,
              credit/debit card information, transaction history, and other
              financial data necessary for conducting transactions through our
              platform.
            </li>
            <li className=" !list-disc">
              Device Information: Such as IP address, device type, operating
              system, and browser type, collected automatically when you access
              our services.
            </li>
            <li className=" !list-disc">
              Usage Data: Information about how you interact with our app,
              including pages visited, features used, and other usage patterns.
            </li>
          </ul>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          2. Methods of Collection
        </h1>
        <div className="space-y-3 px-6">
          <p>We collect information through various methods, including:</p>
          <ul className=" space-y-2">
            <li className="list-disc">
              Directly from you: When you register an account, fill out forms,
              make transactions, or communicate with us through our app or
              customer support channels.
            </li>
            <li className=" !list-disc">
              Automatically: Through the use of cookies, tracking technologies,
              and analytics tools when you access and use our app or website.
            </li>
          </ul>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          3. Purpose of Collection
        </h1>
        <div className="space-y-3 px-6">
          <p>We collect information for the following purposes:</p>
          <ul className=" space-y-2">
            <li className="list-disc">
              To identify customer accounts: Your personal and financial
              information is used to create and manage your account, verify your
              identity, and provide personalized services.
            </li>
            <li className=" !list-disc">
              To communicate updates and complaints: We use your contact
              information to send important updates about our services, respond
              to your inquiries, and address any complaints or feedback you may
              have.
            </li>
            <li className=" !list-disc">
              To verify accounts against fraud: We may use your information to
              detect and prevent fraudulent activities, including unauthorized
              access to your account or suspicious transactions.
            </li>
            <li className=" !list-disc">
              To comply with legal requirements: Your information may be used to
              fulfill legal and regulatory obligations, such as complying with
              anti-money laundering laws, tax regulations, and other legal
              requirements.
            </li>
          </ul>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          4. Use of Information
        </h1>
        <div className="space-y-3 px-6">
          <p>We use the information we collect for the following purposes:</p>
          <ul className=" space-y-2">
            <li className="list-disc">
              To communicate with customers: Your contact information is used to
              send notifications, alerts, and other communications related to
              your account activity and our services.
            </li>
            <li className=" !list-disc">
              To ensure security: We use your information to protect the
              security of our app and its users, including detecting and
              preventing fraud, unauthorized access, and other security threats.
            </li>
            <li className=" !list-disc">
              To provide customer support: Your information helps us address any
              issues, inquiries, or complaints raised by customers, and provide
              timely assistance and support.
            </li>
            <li className=" !list-disc">
              To provide requested services: We use your information to fulfill
              your requests for products, services, or transactions initiated
              through our platform.
            </li>
            <li className=" !list-disc">
              To comply with legal and regulatory requirements: We may use your
              information to comply with applicable laws, regulations, and legal
              processes, including responding to legal requests and enforcing
              our terms of service.
            </li>
          </ul>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          5. Who the Information is Shared with
        </h1>
        <div className="space-y-3 px-6">
          <p>
            We may share your information with third parties under certain
            circumstances, including:
          </p>
          <ul className=" space-y-2">
            <li className="list-disc">
              Service Providers: We may disclose your information to trusted
              service providers who assist us in providing and improving our
              services, such as payment processors, data analytics providers,
              and customer support services.
            </li>
            <li className=" !list-disc">
              Business Partners: We may share information with trusted business
              partners who collaborate with us to offer integrated services or
              promotions.
            </li>
            <li className=" !list-disc">
              Legal Compliance: We may disclose information when required by
              law, legal process, or government request, or to protect our
              rights, property, or safety, or that of others.
            </li>
            <li className=" !list-disc">
              Corporate Transactions: In the event of a merger, acquisition, or
              sale of assets, your information may be transferred to the
              acquiring entity or third parties involved in the transaction.
            </li>
          </ul>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          How Long the Data/Information is Kept
        </h1>
        <div className="space-y-3 px-6">
          <p>
            We retain your information for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy, unless a longer retention
            period is required or permitted by law. The retention period may
            vary depending on the type of information and the purposes for which
            it was collected.
          </p>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          7. When the Data Will Be Deleted
        </h1>
        <div className="space-y-3 px-6">
          <p>
            We will delete or anonymize your information when it is no longer
            necessary for the purposes outlined in this Privacy Policy, or when
            you request its deletion, unless we are required by law to retain it
            or have a legitimate interest in retaining it.
          </p>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          8. Rights Available to the User
        </h1>
        <div className="space-y-3 px-6">
          <p>
            As a user of CoopVest, you have certain rights regarding your
            personal information, including:
          </p>
          <ul className=" space-y-2">
            <li className="list-disc">
              Right to Access: You have the right to request access to the
              personal information we hold about you and receive a copy of your
              data.
            </li>
            <li className=" !list-disc">
              Right to Rectification: You have the right to request correction
              of any inaccurate or incomplete information we hold about you.
            </li>
            <li className=" !list-disc">
              Right to Erasure: You have the right to request deletion of your
              personal information under certain circumstances, such as when it
              is no longer necessary for the purposes for which it was
              collected.
            </li>
            <li className=" !list-disc">
              Right to Restriction: You have the right to request restriction of
              processing of your personal information under certain
              circumstances, such as when you contest the accuracy of the data
              or object to its processing.
            </li>
            <li className=" !list-disc">
              Right to Data Portability: You have the right to receive your
              personal information in a structured, commonly used, and
              machine-readable format and to transmit it to another controller.
            </li>
            <li className=" !list-disc">
              Right to Object: You have the right to object to the processing of
              your personal information under certain circumstances, such as for
              direct marketing purposes or where we rely on legitimate interests
              as the legal basis for processing.
            </li>
          </ul>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <article className="mt-5">
        <h1 className="text-2xl mb-5 font-semibold text-[#034f36]">
          Changes to This Policy
        </h1>
        <div className="space-y-3 px-6">
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes to the policy will be posted on our website and
            will become effective immediately upon posting. We encourage you to
            review this Privacy Policy periodically for any updates or changes.
            Your continued use of CoopVest after the posting of any changes
            indicates your acceptance of those changes.
          </p>
        </div>
      </article>
      <div className="w-full h-[1px] bg-[#034f36] rounded-full mt-5" />
      <p className="my-5">
        We are committed to protecting your privacy and ensuring the security of
        your information. By using our app, you consent to the collection and
        use of your information as described in this Privacy Policy. If you have
        any questions or concerns about our privacy practices, please contact us
        at{" "}
        <Link
          to="mailto:info@coopvest.ng"
          className="font-semibold text-primary underline"
        >
          info@coopvest.ng
        </Link>{" "}
      </p>
    </main>
  );
};

export default Privacy;
