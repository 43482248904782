import React, { useEffect } from "react";
import bgImg from "../../assets/depth.jpg";
type Props = {};

const Loans = (props: Props) => {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <main className="relative flex items-center justify-center min-h-screen">
      <img
        src={bgImg}
        alt=""
        className="absolute -top-4 left-0 w-full h-full object-cover z-[5]"
      />
      <section className="relative z-20 bg-white text-center p-5 shadow-2xl rounded-2xl">
        <h1 className="font-jakarta font-black text-center text-[48px] uppercase text-[#034f36]">
          loans
        </h1>
        <p className="font-semibold">
          This feature is coming soon, hang on tight.
        </p>
      </section>
    </main>
  );
};

export default Loans;
