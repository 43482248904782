import React, { useEffect, useState } from "react";
import agent from "../../assets/individuals.png";
type Props = {};

const Agent = (props: Props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");

  const submitForm = (e: any) => {
    e.preventDefault();
    const encodedName = encodeURIComponent(name);
    const encodedMessage = encodeURIComponent(message);
    window.open(
      `mailto:info@coopvest.ng?subject=from%20${encodedName}%20Agent&body=${encodedMessage}`
    );
  };

  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <main className="px-5 lg:px-6 xl:px-[80px] container mb-16">
      <section className="flex justify-between items-center gap-5 flex-col lg:flex-row mt-5">
        <article>
          <h1 className="text-[#034f36] text-center lg:text-left font-black text-[48px] lg:text-[72px] font-jakarta max-w-[500px]">
            Become an agent for coopvest
          </h1>
        </article>
        <figure>
          <img src={agent} alt="" className="h-auto max-w-[500px]" />
        </figure>
      </section>
      <section className="mt-20">
        <h1 className="max-w-[500px] text-[#034f36] font-jakarta text-[32px] text-center mx-auto font-bold">
          Fill out the form below
        </h1>
        <form
          onSubmit={submitForm}
          className="mx-auto mt-[48px] max-w-[474px] space-y-8"
        >
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="z-20 w-fit rounded-full border border-[#D1D1D1] bg-[#034f36] px-[10px] py-[5px] text-[10px] font-semibold text-white"
            >
              Full Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Remi Folake"
              className="mt-2 w-full rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="z-20 w-fit rounded-full border border-[#D1D1D1] bg-[#034f36] px-[10px] py-[5px] text-[10px] font-semibold text-white"
            >
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="remifolake@email.com"
              className="mt-2 w-full rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="z-20 w-fit rounded-full border border-[#D1D1D1] bg-[#034f36] px-[10px] py-[5px] text-[10px] font-semibold text-white"
            >
              Phone Number
            </label>
            <input
              type="tel"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              className="mt-2 w-full rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor=""
              className="z-20 w-fit rounded-full border border-[#D1D1D1] bg-[#034f36] px-[10px] py-[5px] text-[10px] font-semibold text-white"
            >
              Description
            </label>
            <textarea
              placeholder="I am interested in becoming an agent for coopvest. I have a shop in a busy area and I am looking to expand my business."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="mt-2 h-[167px] w-full resize-none rounded-xl border border-[#D1D1D1] px-[27px] py-[14px] focus:border-primary focus:outline-primary"
            />
          </div>
          <button className="w-full rounded-full bg-primary py-[17px] text-[18px]  font-normal text-white">
            Send Message
          </button>
        </form>
      </section>
    </main>
  );
};

export default Agent;
