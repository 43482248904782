import { HomeIcon, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "./ui/dialog";
import { Link } from "react-router-dom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import ListItem from "./ListItem";
import products from "../lib/data/product";
import { cn } from "../lib/utils";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./ui/hover-card";
import { Sheet, SheetContent, SheetFooter, SheetTrigger } from "./ui/sheet";
import { RiMenu3Fill } from "react-icons/ri";
import { IoIosArrowUp } from "react-icons/io";
import { PiBankBold } from "react-icons/pi";
import { GiReceiveMoney } from "react-icons/gi";
import { Button } from "./ui/button";
import { MdEmail } from "react-icons/md";
import { FaPhone, FaWhatsapp } from "react-icons/fa";

type Props = {};

const Header = (props: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [productIndex, setProductIndex] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const navigation = [
    { name: "Home", href: "/" },
    { name: "About Us", href: "/about" },
    { name: "Products", href: "#" },
    { name: "Our Team", href: "/our-team" },
    { name: "FAQs", href: "/faq" },
  ];

  const handleScroll = () => {
    const isScrolled = window.scrollY > 10;
    if (isScrolled) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={cn(
        "relative clip flex justify-between items-center p-4 lg:px-6 lg:py-4 xl:px-[80px] transition-all duration-300 ease-in-out backdrop-blur-sm overflow-visible",
        scrolled ? "bg-primary text-white" : "bg-white"
      )}
    >
      <div className="w-fit">
        <Link to="#" className="-m-1.5 p-1.5">
          <span className="sr-only">Coopvest</span>

          <img
            className="w-32 h-auto object-contain"
            src={
              scrolled
                ? "https://coopvest.ng/wp-content/uploads/2022/04/cropped-cropped-cropped-cropped-cropped-cropped-cropped-CoopVest-01.png"
                : "/coopvest.png"
            }
            alt=""
          />
        </Link>
      </div>
      <nav className="items-center gap-8 hidden lg:flex">
        <div>
          <Link
            to={navigation[0].href}
            className="text-sm font-semibold font-jakarta"
          >
            <aside
              className={cn(
                "hover:text-primary text-[18px] font-jakarta",
                scrolled && "hover:text-black"
              )}
            >
              {navigation[0].name}
            </aside>
          </Link>
        </div>
        <div>
          <Link
            to={navigation[1].href}
            className="text-sm font-semibold font-jakarta"
          >
            <aside
              className={cn(
                "hover:text-primary text-[18px] font-jakarta",
                scrolled && "hover:text-black"
              )}
            >
              {navigation[1].name}
            </aside>
          </Link>
        </div>
        <div>
          <HoverCard>
            <HoverCardTrigger>
              <Link
                to={navigation[2].href}
                className="text-sm font-semibold font-jakarta"
              >
                <aside
                  className={cn(
                    "hover:text-primary text-[18px] font-jakarta",
                    scrolled && "hover:text-black"
                  )}
                >
                  {navigation[2].name}
                </aside>
              </Link>
            </HoverCardTrigger>
            <HoverCardContent className="">
              <section className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] xl:w-[800px]">
                <aside className="p-4 space-y-4">
                  {products.map((product, i) => (
                    <div onMouseEnter={() => setProductIndex(i)}>
                      <ListItem
                        key={product.title}
                        title={product.title}
                        description={product.description}
                        href={product.href}
                      />
                    </div>
                  ))}
                </aside>
                <aside>
                  {productIndex === 0 && (
                    <aside className="space-y-3">
                      <div className="flex gap-2">
                        <figure className="p-2 bg-primary w-fit h-fit rounded-md">
                          <img
                            src="/pos.svg"
                            alt="pos"
                            className="h-5 w-5 object-cover"
                          />
                        </figure>
                        <article>
                          <h1 className="font-jakarta font-semibold">
                            POS Services
                          </h1>
                          <p className="text-sm">
                            Perform secured transaction with our cost-effective
                            devices.
                          </p>
                        </article>
                      </div>
                      <div className="flex gap-2">
                        <figure className="p-2 bg-primary w-fit h-fit rounded-md">
                          <GiReceiveMoney className="text-xl text-white" />
                        </figure>
                        <article>
                          <h1 className="font-jakarta font-semibold">
                            Affordable and Accessible Services
                          </h1>
                          <p className="text-sm">
                            Our services are accessible and affordable for all.
                          </p>
                        </article>
                      </div>
                    </aside>
                  )}
                  {productIndex === 1 && (
                    <aside className="space-y-3">
                      <div className="flex gap-2">
                        <figure className="p-2 bg-primary w-fit h-fit rounded-md">
                          <PiBankBold className="text-xl text-white" />
                        </figure>
                        <article>
                          <h1 className="font-jakarta font-semibold">
                            Digital Banking Services
                          </h1>
                          <p className="text-sm">
                            Have access to financial services at your
                            convenience.
                          </p>
                        </article>
                      </div>
                    </aside>
                  )}
                </aside>
              </section>
            </HoverCardContent>
          </HoverCard>
        </div>
        <div>
          <Link
            to={navigation[3].href}
            className="text-sm font-semibold font-jakarta"
          >
            <aside
              className={cn(
                "hover:text-primary text-[18px] font-jakarta",
                scrolled && "hover:text-black"
              )}
            >
              {navigation[3].name}
            </aside>
          </Link>
        </div>
        <div>
          <Link
            to={navigation[4].href}
            className="text-sm font-semibold font-jakarta"
          >
            <aside
              className={cn(
                "hover:text-primary text-[18px] font-jakarta",
                scrolled && "hover:text-black"
              )}
            >
              {navigation[4].name}
            </aside>
          </Link>
        </div>
      </nav>
      {/* <NavigationMenu className="w-fit transition-all duration-100 ease-in-out">
        <NavigationMenuList className="hidden lg:flex !w-fit">
          <NavigationMenuItem className="">
           
          </NavigationMenuItem>
          <NavigationMenuItem>
            <Link
              to={navigation[1].href}
              className="text-sm font-semibold font-jakarta"
            >
              <aside
                className={cn(
                  "hover:text-primary text-[18px] font-jakarta",
                  scrolled && "hover:text-black"
                )}
              >
                {navigation[1].name}
              </aside>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <NavigationMenuTrigger className="bg-transparent font-jakarta !list-none text-[18px] font-semibold">
              {navigation[2].name}
            </NavigationMenuTrigger>
            <NavigationMenuContent>
              <aside className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px] xl:w-[800px] list-none">
                <aside className="p-4 space-y-4">
                  {products.map((product, i) => (
                    <div onMouseEnter={() => setProductIndex(i)}>
                      <ListItem
                        key={product.title}
                        title={product.title}
                        description={product.description}
                      />
                    </div>
                  ))}
                </aside>
                <aside className="max-h-[350px] overflow-y-auto scrollbar-none relative flex gap-2 items-start">
                  <div className="bg-primary w-[2px] h-[70%] sticky top-[15%] bottom-0 my-auto" />
                  <div>
                    {productIndex === 0 && (
                      <div className="text-sm font-normal">
                        <h1 className="font-medium text-[18px]">
                          <strong>Introduction</strong>{" "}
                        </h1>
                        <p className=" mt-2">
                          COOPMONI which is developed by COOPVEST Limited and
                          powered by Waxed Mobile in collaboration with
                          COOPERATIVE MORTGAGE BANK, is an agency banking
                          platform. Its mission is to make banking services more
                          accessible to cooperative societies and their members,
                          particularly those residing in rural, remote, and
                          unbanked areas of Nigeria.
                        </p>
                        <div className="mt-2">
                          <p>The key features and benefits of COOPMONI.</p>
                          <ul className="space-y-2 pt-2">
                            <li>
                              1. Target Audience: COOPMONI caters to low-income
                              earners aged 18 and above. It specifically
                              addresses the financial needs of individuals who
                              have limited access to formal banking services.
                            </li>
                            <li>
                              2. Affordable POS Devices: COOPMONI offers
                              cost-effective point-of-sale (POS) devices. These
                              devices enable secure transactions and enhance
                              financial inclusion.
                            </li>
                            <li>
                              3. Security Compliance: The platform prioritizes
                              security and compliance. Users can trust COOPMONI
                              for safe and secure financial transactions.
                            </li>
                            <li>
                              4. Seamless Experience: COOPMONI aims for a
                              seamless user experience. From registration to
                              daily transactions, the platform is designed for
                              ease of use.
                            </li>
                            <li>
                              5. Affordability and Accessibility: COOPMONI is
                              not only accessible but also affordable. It
                              empowers users in under served areas to manage
                              their finances conveniently.
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {productIndex === 1 && (
                      <div className="text-sm font-normal">
                        <h1 className="font-medium text-[18px]">
                          <strong>Introduction</strong>{" "}
                        </h1>
                        <p className=" mt-2">
                          Coopurse is a digital banking service that rewards the
                          banking experience of users with smartphones. Our
                          mobile application services include onboarding and
                          account opening, transfers, bills payment, savings,
                          investment and loan.
                        </p>
                        <div className="mt-2">
                          <p>
                            We provide the following seamless services under
                            Coopurse:
                          </p>
                          <ul className="space-y-2 pt-2">
                            <li>
                              <strong>Account opening:</strong> Onboarding of
                              new customer without visiting any contact center
                              or branch.
                            </li>
                            <li>
                              <strong>Transfer:</strong> Coopurse provides this
                              service to any customer willing to transfer funds
                              to any bank in Nigeria.
                            </li>
                            <li>
                              <strong>Bills payment:</strong> A service for
                              making bill-related payments and subscriptions for
                              DSTV, GOTV, IBEDC, NECO, WAEC, etc. Airtime:
                              Airtime vending on popular mobile networks in
                              Nigeria.
                            </li>
                            <li>
                              <strong>Savings:</strong> This service provides
                              savings platform for customers with competitive
                              interest rate.
                            </li>
                            <li>
                              <strong>Investments:</strong> Like the savings
                              service, we provide an investment platform with
                              higher returns on investment.
                            </li>
                            <li>
                              <strong>Loans:</strong> Micro loans are provided
                              to worthy customers for urgent use to be paid back
                              between 15 days and 3 months depending on the
                              amount (minimum of 5,000 and a maximum of 50,000).
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                    {productIndex === 2 && (
                      <div className="text-sm font-normal flex justify-center text-muted-foreground h-full items-center">
                        <p>COOPZONE is coming soon</p>
                      </div>
                    )}
                  </div>
                </aside>
              </aside>
            </NavigationMenuContent>
          </NavigationMenuItem>
          <NavigationMenuItem className="">
            <Link to={navigation[3].href} className="text-sm font-semibold">
              <aside
                className={cn(
                  "hover:text-primary text-[18px] font-jakarta",
                  scrolled && "hover:text-black"
                )}
              >
                {navigation[3].name}
              </aside>
            </Link>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu> */}

      <div className="hidden lg:block">
        <Popover>
          <PopoverTrigger>
            <Button size={"lg"} className="text-white">
              Contact us
            </Button>
          </PopoverTrigger>
          <PopoverContent className="space-y-3">
            <Link to="mailto:info@coopvest.ng">
              <div className="flex items-center gap-3 p-2 hover:bg-primary/10 hover:bg-gray-300 rounded-xl">
                <>
                  <MdEmail className="h-6 w-6" />
                </>
                <p>Email</p>
              </div>
            </Link>
            <div className="flex items-center gap-3 p-2 hover:bg-primary/10 rounded-xl">
              <>
                <FaWhatsapp className="h-6 w-6 text-green-700" />
              </>
              <p>Whatsapp</p>
            </div>
            <div className="flex items-center gap-3 p-2 hover:bg-primary/10 rounded-xl">
              <>
                <FaPhone className="h-6 w-6 text-green-700" />
              </>
              <p>+2348158393916</p>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <div className="lg:hidden">
        <Sheet onOpenChange={setMobileMenuOpen} open={mobileMenuOpen}>
          <SheetTrigger>
            <RiMenu3Fill
              className={cn("text-gray-900 text-2xl", scrolled && "text-white")}
            />
          </SheetTrigger>
          <SheetContent className="">
            <header>
              <img
                className="w-32 h-auto object-cover"
                src={"/coopvest.png"}
                alt=""
              />
            </header>
            <section className="space-y-5  font-semibold mt-6">
              <nav onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <Link to={navigation[0].href} className="">
                  {navigation[0].name}
                </Link>
              </nav>
              <nav onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <Link to={navigation[1].href} className="">
                  {navigation[1].name}
                </Link>
              </nav>
              <nav>
                <aside>
                  <button
                    onClick={() => setIsProductsOpen(!isProductsOpen)}
                    className="group flex gap-3 items-center"
                  >
                    <div className="">{navigation[2].name}</div>
                    <div>
                      <IoIosArrowUp
                        className={cn(
                          "text-text-900 rotate-180 transition-all duration-300 ease-in-out",
                          isProductsOpen && "rotate-0"
                        )}
                      />
                    </div>
                  </button>
                </aside>
                <aside
                  className={cn(
                    "flex items-center gap-3 overflow-x-auto h-0 transition-all duration-300 ease-in-out",
                    isProductsOpen && "h-20"
                  )}
                >
                  {products.map((product, i) => (
                    <aside className="h-fit px-3 min-w-[91px] max-w-[91px] rounded-full border border-primary relative">
                      {product.href ? (
                        <Link
                          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                          to={product.href}
                          className="!text-[12px] p-0"
                        >
                          {product.title}
                        </Link>
                      ) : (
                        <div className="">
                          <p className="text-[8px] text-white bg-black p-1 rounded-full absolute -top-4 left-2">
                            coming soon
                          </p>
                          <p className="!text-[12px] ">{product.title}</p>
                        </div>
                      )}
                    </aside>
                  ))}
                </aside>
              </nav>
              <nav onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <Link to={navigation[3].href} className="">
                  {navigation[3].name}
                </Link>
              </nav>
              <nav onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                <Link to={navigation[4].href} className="">
                  {navigation[4].name}
                </Link>
              </nav>
            </section>
            <div className="mt-8">
              <Popover>
                <PopoverTrigger>
                  <Button size={"lg"} className="text-white">
                    Contact us
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="space-y-3">
                  <Link to="mailto:info@coopvest.ng">
                    <div className="flex items-center gap-3 p-2 hover:bg-primary/10 hover:bg-gray-300 rounded-xl">
                      <>
                        <MdEmail className="h-6 w-6" />
                      </>
                      <p>Email</p>
                    </div>
                  </Link>
                  <div className="flex items-center gap-3 p-2 hover:bg-primary/10 rounded-xl">
                    <>
                      <FaWhatsapp className="h-6 w-6 text-green-700" />
                    </>
                    <p>Whatsapp</p>
                  </div>
                  <div className="flex items-center gap-3 p-2 hover:bg-primary/10 rounded-xl">
                    <>
                      <FaPhone className="h-6 w-6 text-green-700" />
                    </>
                    <p>+2348161203489</p>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </SheetContent>
        </Sheet>
      </div>
      {/* </nav> */}
    </header>
  );
};

export default Header;
