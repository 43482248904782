import React from "react";
import { Boxes } from "../ui/background-boxes";
import teamImage from "../../assets/coopvest_team_7.jpg";
import { mockup1, mockup2, mockup7 } from "../../assets/mockups";

type Props = {};

const Hero = (props: Props) => {
  return (
    <main className="relative h-fit overflow-hidden items-center justify-center text-center lg:text-left  px-5 clip-m pb-16 flex felx-col lg:flex-row gap-5">
      <section className="mt-8">
        <Boxes />
        <h1 className="relative max-w-2xl text-[48px] font-jakarta font-black">
          <span className="text-primary">COOPURSE</span> (DIGITAL BANKING)
        </h1>
        <p className="relative text-muted-foreground max-w-xl mt-3 text-[18px]">
          As a comprehensive mobile application, COOPURSE empowers cooperatives,
          and other individual to manage their daily savings and track
          operational payments seamlessly.
        </p>
      </section>
      <section className="relative hidden lg:block">
        <div className="h-24 w-24  bg-[#FFDE82] flex justify-center items-center absolute top-12 left-0 z-10 rounded-full">
          <img src="/chart.png" alt="" />
        </div>
        <div className="h-24 w-24 bg-gray-100 absolute flex items-center justify-center bottom-12 right-0 z-10 rounded-full">
          <img src="/increase.png" alt="" className="" />
        </div>
        <div className="bg-[#11D064] relative mt-16 h-[400px] w-[400px] rounded-full overflow-hidden flex items-center justify-center">
          <img
            src={teamImage}
            alt=""
            className="w-[400px] h-[400px] object-cover"
          />
        </div>
      </section>
    </main>
  );
};

export default Hero;
