import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

type Props = {};

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <main className="relative w-full font-inter bg-[#f2f7f8]">
      <header className="sticky top-0 z-50 overflow-visible">
        <Header />
      </header>
      <div className="relative ">{children}</div>
      <footer className="">
        <Footer />
      </footer>
    </main>
  );
};

export default Layout;
