import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import loan1 from "../../assets/instant-loan.png";
import pos1 from "../../assets/affordable-pos.png";
import agency1 from "../../assets/investment-opportunities.png";
import customer from "../../assets/customer-support.png";
import { Button } from "../ui/button";
import { BsArrowReturnRight } from "react-icons/bs";
import { Link } from "react-router-dom";

type Props = {};

const GetStarted = (props: Props) => {
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);
  const firstInView = useInView(ref1);
  const secondInView = useInView(ref2);
  const thirdInView = useInView(ref3);
  const fourthInView = useInView(ref4);
  const variant1 = {
    hidden: { opacity: 0, x: -100 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  const variant2 = {
    hidden: { opacity: 0, x: 100 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <main className="max-w-5xl mx-auto mt-16 lg:mt-2">
      <article className="text-center">
        <h1 className=" font-jakarta text-4xl text-[#034f36] capitalize font-black">
          why <span className="">Coopvest</span>?
        </h1>
        <p className="text-muted-foreground text-base tracking-tight mt-3">
          At Coopvest, we’re not just another financial technology company.
          We’re your trusted partner, committed to transforming the way you
          experience banking.
        </p>
      </article>
      <section className="mt-16 space-y-16">
        <motion.aside
          ref={ref1}
          variants={variant1}
          animate={firstInView ? "show" : "hidden"}
          className="flex flex-col group lg:flex-row justify-between lg:items-center p-11 bg-primary/20 pt-16 rounded-xl"
        >
          <motion.div className="max-w-80  flex  items-center justify-center mx-auto relative z-10 h-[300px] w-[300px]  rounded-lg">
            <img
              src={loan1}
              alt=""
              className="h-[300px] w-[250px] mx-auto lg:mx-0 md:w-[300px] rounded-lg object-contain group-hover:scale-105 duration-300 ease-in-out"
            />
          </motion.div>
          <article className="max-w-xl mx-auto text-center lg:text-left lg:mx-0">
            <h1 className=" font-semibold text-[30px] md:text-[48px] text-[#034f36]">
              Instant Loans
            </h1>
            <p>
              Whether you’re a cooperative of petty traders, a solo retail
              marketer, or a corporate professional in need of fast financial
              solutions, we’ve got you covered. Contact us to discover our
              various loan options and terms.
            </p>
          </article>
        </motion.aside>
        <motion.aside
          ref={ref2}
          variants={variant2}
          animate={secondInView ? "show" : "hidden"}
          className="flex flex-col-reverse group lg:flex-row justify-between lg:items-center p-11 bg-primary/20 pt-16 rounded-xl"
        >
          <article className="max-w-xl mx-auto text-center lg:text-left lg:mx-0">
            <h1 className=" font-semibold text-[30px] md:text-[48px] text-[#034f36]">
              Affordable POS
            </h1>
            <p>
              COOPMONI offers cost-effective point-of-sale (POS) devices. These
              devices enable secure transactions and enhance financial
              inclusion.
            </p>
          </article>
          <div className="relative max-w-80 mx-auto h-[300px] w-[300px] rounded-lg ">
            <img
              src={pos1}
              alt=""
              className="h-[300px] w-[250px] mx-auto lg:mx-0 md:w-[300px] rounded-lg group-hover:scale-105 object-contain duration-300 ease-in-out"
            />
          </div>
        </motion.aside>
        <motion.aside
          ref={ref3}
          variants={variant1}
          animate={thirdInView ? "show" : "hidden"}
          className="flex flex-col group lg:flex-row justify-between lg:items-center p-11 bg-primary/20 pt-16 rounded-xl"
        >
          <div className="max-w-80 mx-auto relative rounded-lg h-[300px] w-[300px]">
            <img
              src={agency1}
              alt=""
              className=" h-[300px] w-[250px] mx-auto lg:mx-0 md:w-[300px] rounded-lg object-contain group-hover:scale-105 duration-300 ease-in-out"
            />
          </div>
          <article className="max-w-xl mx-auto text-center lg:text-left lg:mx-0">
            <h1 className="font-semibold text-[30px] md:text-[48px] text-[#034f36]">
              Investment Opportunities
            </h1>
            <p>
              Access a variety of investment options tailored to cooperative
              members’ goals and risk preferences.
            </p>
          </article>
        </motion.aside>
        <motion.aside
          ref={ref4}
          variants={variant2}
          animate={fourthInView ? "show" : "hidden"}
          className="flex flex-col-reverse group lg:flex-row justify-between lg:items-center p-11 bg-primary/20 pt-16 rounded-xl"
        >
          <article className="max-w-xl mx-auto text-center lg:text-left lg:mx-0">
            <h1 className="font-semibold text-[30px] md:text-[48px] text-[#034f36]">
              Customer Support
            </h1>
            <p>
              We prioritize your needs and feedback, our dedicated customer
              support team is available around the clock to provide assistance
              and ensure a seamless user experience.
            </p>
            <Link
              to={"tel:+2348161203489"}
              className="flex gap-5 items-center mt-5 text-primary hover:underline"
            >
              <>
                <BsArrowReturnRight className="h-8 w-8" />
              </>
              <p>Contact Support</p>
            </Link>
          </article>
          <div className=" max-w-80 mx-auto relative rounded-lg h-[300px] w-[300px]">
            <img
              src={customer}
              alt=""
              className=" h-[300px] w-[250px] mx-auto lg:mx-0 md:w-[300px] object-contain rounded-lg group-hover:scale-105 duration-300 ease-in-out"
            />
          </div>
        </motion.aside>
      </section>
    </main>
  );
};

export default GetStarted;
