import React from "react";
import processing from "../../assets/process-payments.png";
import investment from "../../assets/onboard-diverse.png";
import loan from "../../assets/loans.png";
import cooperatives from "../../assets/cooporatives.png";
import blob1 from "../../assets/blob (4).svg";
import blob2 from "../../assets/blob (3).svg";
import blob from "../../assets/blob.svg";
import agent from "../../assets/individuals.png";
import merchant from "../../assets/merchants.jpg";
import { Link } from "react-router-dom";
import { BsArrowReturnRight } from "react-icons/bs";

type Props = {};

const Coop = (props: Props) => {
  return (
    <main className="px-5 xl:px-[80px]">
      <article>
        <h1 className="text-[26px] lg:text-[48px] text-center font-jakarta font-black">
          What is <span className="text-primary">COOPMONI</span>?
        </h1>
        <p className="max-w-6xl mx-auto">
          Coopmoni redefines agent banking by delivering a suite of digital
          financial services that cater to the needs of Cooperatives,
          Individuals, and Businesses across Nigeria. From bustling sub-urban
          centers to the most remote corners of the country, Coopmoni ensures
          that digital financial empowerment is just a click away.
        </p>
        <p className="max-w-6xl mx-auto mt-5">
          In partnership with the Cooperative Movement, Cooperator Bank and
          powered by Horizon Pay, COOPMONI stands as a beacon of progress,
          bringing digital financial services to the doorstep of cooperative
          societies, their members, business owners (merchants) and individuals.
          Our commitment is unwavering, especially to those in rural,
          underserved, and traditionally unbanked regions, ensuring that every
          Nigerian has the opportunity to thrive in the digital economy.
        </p>
      </article>
      <section className="mt-7">
        <h1 className="font-jakarta font-black text-[32px] text-center text-[#034f36] uppercase p-6">
          Key features
        </h1>
        <aside className="flex justify-between max-w-4xl mx-auto pt-5 ">
          <div className="relative md:hidden h-[550px] w-1 bg-[#034f36] mr-5">
            <div className="text-white absolute top-0 -left-[14px] right-0 h-8 w-8 flex justify-center items-center rounded-full  bg-primary">
              1
            </div>
          </div>
          <div className=" w-full flex flex-col items-center gap-5 md:flex-row md:justify-between">
            <figure className="">
              <img
                src={processing}
                alt=""
                className="h-[300px] w-[300px] object-contain"
              />
            </figure>
            <div className="hidden md:block relative h-[300px] w-1 bg-[#034f36]">
              <div className="text-white absolute top-0 -left-[14px] right-0 h-8 w-8 flex justify-center items-center rounded-full  bg-primary">
                1
              </div>
            </div>
            <article className="max-w-[300px] space-y-5">
              <h1 className="font-jakarta text-[20px] font-semibold">
                Processing payment
              </h1>
              <p className="text-[15px] font-light">
                Process payments efficiently with our streamlined system. From
                seamless transactions to secure processing, our platform ensures
                swift and hassle-free payments for your convenience. Say goodbye
                to delays and complications, and hello to effortless financial
                transactions.
              </p>
            </article>
          </div>
        </aside>
        <aside className="flex justify-between max-w-4xl mx-auto">
          <div className="relative h-[620px] w-1 bg-[#034f36] mr-5 md:hidden">
            <div className="text-white absolute top-0 -left-[14px] right-0 h-8 w-8 flex justify-center items-center rounded-full  bg-primary">
              2
            </div>
          </div>
          <div className="w-full flex flex-col-reverse gap-5 items-center md:flex-row md:justify-between">
            <article className="max-w-[300px] space-y-5 py-5">
              <h1 className="font-jakarta text-[20px] font-semibold capitalize">
                Onboard to our diverse investment opportunities
              </h1>
              <p className="text-[15px] font-light">
                Embark on a journey of diverse investment opportunities with us.
                From traditional to innovative ventures, our platform offers a
                range of options tailored to suit your preferences and financial
                goals. Explore, diversify, and unlock new pathways to financial
                growth with ease.
              </p>
            </article>
            <div className="hidden md:block relative h-[300px] w-1 bg-[#034f36]">
              <div className="text-white absolute top-0 -left-[14px] right-0 h-8 w-8 flex justify-center items-center rounded-full  bg-primary">
                2
              </div>
            </div>
            <figure className="">
              <img
                src={investment}
                alt=""
                className="h-[300px] w-[300px] object-contain"
              />
            </figure>
          </div>
        </aside>
        <aside className="flex justify-between max-w-4xl mx-auto">
          <div className="relative h-[540px] w-1 bg-[#034f36] mr-5 md:hidden">
            <div className="text-white absolute top-0 -left-[14px] right-0 h-8 w-8 flex justify-center items-center rounded-full  bg-primary">
              3
            </div>
          </div>
          <div className="w-full flex flex-col gap-5 items-center md:flex-row md:justify-between">
            <figure className="">
              <img
                src={loan}
                alt=""
                className="h-[300px] w-[300px] object-contain"
              />
            </figure>
            <div className="hidden md:block relative h-[300px] w-1 bg-[#034f36]">
              <div className="text-white absolute top-0 -left-[14px] right-0 h-8 w-8 flex justify-center items-center rounded-full  bg-primary">
                3
              </div>
            </div>
            <article className="max-w-[300px] space-y-5">
              <h1 className="font-jakarta text-[20px] font-semibold">Loans</h1>
              <p className="text-[15px] font-light">
                Unlock financial growth with Coopmoni's loans. Tailored for all,
                our digital platform ensures seamless access to support your
                dreams. Join us in revolutionizing financial inclusion for every
                Nigerian.
              </p>
            </article>
          </div>
        </aside>
      </section>
      <section className="p-8 !pb-20 xl:px-20 mt-28 flex flex-col overflow-hidden lg:flex-row gap-16 lg:gap-16 h-[900px] lg:h-[700px] items-center justify-between bg-primary/40 mx-auto rounded-3xl">
        <article className="max-w-[350px] text-center lg:text-left">
          <h1 className="font-jakarta text-[32px] lg:text-[52px] text-[#034f36] font-black">
            For Individuals
          </h1>
          <p className="font-light text-[20px] mt-5 lg:mt-0 lg:text-[24px]">
            Are you an individual who wants to make extra cash, click the link
            below to be an agent
          </p>
          <div className="mt-8 text-white">
            <Link to="/become-an-agent">
              <div className="flex items-center gap-3">
                <>
                  <BsArrowReturnRight className="h-8 w-8 text-primary" />
                </>

                <p className=" hover:underline font-jakarta font-medium capitalize">
                  Become an Agent
                </p>
              </div>
            </Link>
          </div>
        </article>
        <figure className="relative z-10 p-3 h-[450px]">
          <img
            src={blob1}
            alt=""
            className="absolute top-5 -left-16 w-48 h-48 z-[2] opacity-40"
          />
          <img
            src={blob2}
            alt=""
            className="absolute bottom-0 -right-8 w-32 h-32 z-[2] opacity-40"
          />
          <img
            src={agent}
            alt=""
            className="h-full w-[700px] relative z-10 object-cover rounded-2xl"
          />
        </figure>
      </section>
      <section className="p-8 !pb-20 xl:px-20 mt-28 flex flex-col overflow-hidden lg:flex-row gap-16 lg:gap-16 h-[900px] lg:h-[700px] items-center justify-between bg-primary/40 mx-auto rounded-3xl">
        <article className="max-w-[350px] text-center lg:text-left">
          <h1 className="font-jakarta text-[32px] lg:text-[52px] text-[#034f36] font-black">
            For Merchants
          </h1>

          <p className="font-light text-[20px] mt-5 lg:text-[24px]">
            Are you a business owner who wants to have a channel to process
            payments for goods purchased or services rendered
          </p>
          <div className="mt-4 text-white">
            <Link to="/become-a-merchant">
              <div className="flex items-center gap-3">
                <>
                  <BsArrowReturnRight className="h-8 w-8 text-primary" />
                </>

                <p className=" hover:underline font-jakarta font-medium capitalize">
                  Become a Merchant
                </p>
              </div>
            </Link>
          </div>
        </article>
        <figure className="relative z-10 p-3 h-[450px]">
          <img
            src={blob1}
            alt=""
            className="absolute top-5 -left-16 w-48 h-48 z-[2] opacity-40"
          />
          <img
            src={blob2}
            alt=""
            className="absolute bottom-0 -right-8 w-32 h-32 z-[2] opacity-40"
          />
          <img
            src={merchant}
            alt=""
            className="h-full w-[700px] relative z-10 object-cover rounded-2xl"
          />
        </figure>
      </section>
      <section className="p-8 xl:px-20 mt-28 flex flex-col lg:flex-row gap-16 overflow-hidden lg:gap-16  h-[900px] lg:h-[600px] items-center justify-between bg-primary/40 mx-auto rounded-3xl">
        <article className="max-w-[350px] text-center lg:text-left">
          <h1 className="font-jakarta text-[32px] lg:text-[52px] text-[#034f36] font-black">
            For cooporatives
          </h1>
          <p className="font-light text-[20px] mt-5 lg:text-[24px]">
            Are you a Cooperative society and want to create wealth for your
            members? Click the link below to learn about the different financial
            options.
          </p>
          <div className="mt-8 text-white">
            <Link to="/register-cooporative">
              <div className="flex items-center gap-3">
                <>
                  <BsArrowReturnRight className="h-8 w-8 text-primary" />
                </>

                <p className=" hover:underline font-jakarta font-medium capitalize">
                  Explore financial options
                </p>
              </div>
            </Link>
          </div>
        </article>
        <figure className="relative z-10 p-8 lg:p-3 h-[450px]">
          <img
            src={blob1}
            alt=""
            className="absolute top-5 -left-16 w-48 h-48 z-[2] opacity-40"
          />
          <img
            src={blob2}
            alt=""
            className="absolute bottom-0 -right-8 w-32 h-32 z-[2] opacity-40"
          />
          <img
            src={cooperatives}
            alt=""
            className="h-full w-[700px] relative z-10 object-cover rounded-2xl"
          />
        </figure>
      </section>
      <section className="mt-28 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-items-center gap-8">
        <aside className="transition-all duration-200 ease-in-out max-w-xl space-y-5 border border-gray-500/20 rounded-3xl p-6 shadow-md hover:scale-[102%]">
          <figure>
            <img src="/target.png" alt="" className="w-[32px] h-[32px]" />
          </figure>
          <h1 className="text-[18px] font-GontserratRegular font-semibold text-primary">
            Target Audience
          </h1>
          <p className="text-base">
            COOPMONI caters for all, including cooperative societies,
            cooperators seeking empowerment and avenues for growth, as well as
            the broader population of individuals in unbanked areas that are
            looking for accessible and inclusive financial services that can
            enhance their financial well-being and unlock opportunities for
            economic advancement.
          </p>
        </aside>
        <aside className="transition-all duration-200 ease-in-out max-w-xl space-y-5 border border-gray-500/20 rounded-3xl p-6 shadow-md hover:scale-[102%]">
          <figure>
            <img src="/payment.png" alt="" className="w-[32px] h-[32px]" />
          </figure>
          <h1 className="text-[18px] font-GontserratRegular font-semibold text-primary">
            Affordable POS Device
          </h1>
          <p className="text-base">
            COOPMONI offers cost-effective point-of-sale (POS) devices. These
            devices enable secure transactions and enhance financial inclusion.
          </p>
        </aside>
        <aside className="transition-all duration-200 ease-in-out max-w-xl space-y-5 border border-gray-500/20 rounded-3xl p-6 shadow-md hover:scale-[102%]">
          <figure>
            <img src="/shield.png" alt="" className="w-[32px] h-[32px]" />
          </figure>
          <h1 className="text-[18px] font-GontserratRegular font-semibold text-primary">
            Security Compliance
          </h1>
          <p className="text-base">
            The platform prioritizes security and compliance. Users can trust
            COOPMONI for safe and secure financial transactions.
          </p>
        </aside>
        <aside className="transition-all duration-200 ease-in-out max-w-xl space-y-5 border border-gray-500/20 rounded-3xl p-6 shadow-md hover:scale-[102%]">
          <figure>
            <img
              src="/best-customer-experience.png"
              alt=""
              className="w-[32px] h-[32px]"
            />
          </figure>
          <h1 className="text-[18px] font-GontserratRegular font-semibold text-primary">
            Seamless Experience
          </h1>
          <p className="text-base">
            COOPMONI aims for a seamless user experience. From registration to
            daily transactions, the platform is designed for ease of use.
          </p>
        </aside>
        <aside className="transition-all duration-200 ease-in-out max-w-xl space-y-5 border border-gray-500/20 rounded-3xl p-6 shadow-md hover:scale-[102%]">
          <figure>
            <img src="/money.png" alt="" className="w-[32px] h-[32px]" />
          </figure>
          <h1 className="text-[18px] font-GontserratRegular font-semibold text-primary">
            Affordability and Accessibility
          </h1>
          <p className="text-base">
            COOPMONI is not only accessible but also affordable. It empowers
            users in under served areas to manage their finances conveniently.
          </p>
        </aside>
      </section>
    </main>
  );
};

export default Coop;
