import React from "react";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import twitter from "../assets/twitter.png";
import linkedin from "../assets/linkedin.png";

import { Link } from "react-router-dom";

type Props = {};

const Footer = (props: Props) => {
  const footerNavigation = {
    main: [
      { name: "Blog", href: "#" },
      { name: "Jobs", href: "#" },
      { name: "Press", href: "#" },
      { name: "Accessibility", href: "#" },
      { name: "Partners", href: "#" },
    ],
    social: [
      {
        name: "Facebook",
        href: "https://web.facebook.com/profile.php?id=100068735147380&_rdc=1&_rdr",
        icon: facebook,
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/coopvestng/?hl=en",
        icon: instagram,
      },
      {
        name: "X",
        href: "https://twitter.com/coopvestng",
        icon: twitter,
      },
      {
        name: "LinkedIn",
        href: "https://www.linkedin.com/in/coopvest-ng-a17195230/",
        icon: linkedin,
      },
      // {
      //   name: "GitHub",
      //   href: "#",
      //   icon: (props: any) => (
      //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      //       <path
      //         fillRule="evenodd"
      //         d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
      //         clipRule="evenodd"
      //       />
      //     </svg>
      //   ),
      // },
      // {
      //   name: "YouTube",
      //   href: "#",
      //   icon: (props: any) => (
      //     <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
      //       <path
      //         fillRule="evenodd"
      //         d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
      //         clipRule="evenodd"
      //       />
      //     </svg>
      //   ),
      // },
    ],
  };

  return (
    <footer className="bg-white  p-4 lg:px-6 xl:px-[80px] py-11 relative z-20">
      <section className="capitalize grid grid-cols-1 md:grid-cols-2 gap-5">
        <div className="w-fit">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Coopvest</span>

            <img
              className="w-32 h-auto object-contain"
              src={"/coopvest.png"}
              alt=""
            />
          </Link>
        </div>
        <section className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <aside>
            <h1 className="text-[18px] font-semibold font-jakarta text-[#034f36] pb-5">
              Products
            </h1>
            <ul className="text-sm space-y-3">
              <li>
                <Link to="/coopmoni">coopmoni</Link>
              </li>
              <li>
                <Link to="/coopurse">coopurse</Link>
              </li>
              <li>
                <Link to="/loans">loans</Link>
              </li>
              <li>
                <Link to="/coopzone">coopzone</Link>
              </li>
            </ul>
          </aside>
          <aside>
            <h1 className="text-[18px] font-semibold font-jakarta text-[#034f36] pb-5">
              Company
            </h1>
            <ul className="text-sm space-y-3">
              <li>
                <Link to="/about">about us</Link>
              </li>
              <li>
                <Link to="faq">FAQs</Link>
              </li>
              <li>
                <Link to="/our-team">team</Link>
              </li>
              <li>
                <Link to="/become-an-agent">become an agent</Link>
              </li>
              <li>
                <Link to="/become-a-merchant">become a merchant</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy & Legal</Link>
              </li>
            </ul>
          </aside>
        </section>
      </section>
      <section className="mt-3 text-sm">
        <article className="">
          <p>Phone: +2348161203489</p>
          <p className="">Mail: info@coopvest.ng</p>
        </article>
        <article>16, University Crescent, Bodija, Ibadan, Oyo State</article>
        <article className="mt-4">
          <h1 className="font-jakarta text-[18px] font-semibold text-[#034f36]">
            Get in touch with us
          </h1>
          <div className="flex items-center gap-3 mt-2">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target="__blank"
                referrerPolicy="no-referrer"
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <img
                  src={item.icon} // Convert item.icon to string
                  alt=""
                  className="h-8 w-8 object-contain"
                  // aria-hidden="true"
                />
              </a>
            ))}
          </div>
        </article>
      </section>
      {/* <section className="mx-auto max-w-7xl  flex flex-col gap-6 lg:flex-row-reverse justify-between">
        <nav className="" aria-label="Footer">
          <h1 className="text-[18px] font-GontserratBold">CONTACT</h1>
          <article>
            <p>Phone: +2348158393916, +2349053994506</p>
            <p className="mt-1">Mail: info@coopvest.ng</p>
          </article>
        </nav>
        <nav>
          <h1 className="text-[18px] font-GontserratBold uppercase">Socials</h1>
          <div className=" flex gap-5">
            {footerNavigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500"
              >
                <span className="sr-only">{item.name}</span>
                <img
                  src={item.icon} // Convert item.icon to string
                  alt=""
                  className="h-8 w-8 object-contain"
                  // aria-hidden="true"
                />
              </a>
            ))}
          </div>
        </nav>
      </section> */}
      <p className="pt-3  text-xs leading-5 text-gray-500 text-center w-full">
        &copy; {new Date().getFullYear()} Coopvest. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
