import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { AccordionItem } from "@radix-ui/react-accordion";
import { useEffect } from "react";

const faqs = [
  {
    id: 1,
    question: "What is COOPMONI?",
    answer:
      "COOPMONI is an agency banking platform that enables clients and customers to carry out financial and monetary transactions. It provides a convenient way for users to manage their funds, transfer money, and perform other essential banking services.",
  },
  {
    id: 2,
    question: "What is Cash-out?",
    answer:
      "Cash-out refers to the process where agents receive inbound funds from their respective customers using point-of-sale (POS) devices. It allows customers to withdraw cash or make payments conveniently through authorized agents.",
  },
  {
    id: 3,
    question: "Who is an agent?",
    answer:
      "An agent is a person or an entity who is engaged to provide financial services like cashout using its physical location.",
  },
  {
    id: 4,
    question: "Who is a merchant?",
    answer:
      "A merchant is a person or business involved in the trade of goods and services for payment and can use the point of sale (POS) devices to process payments for such goods and services rendered.",
  },
  {
    id: 5,
    question: "Do I need a terminal ID?",
    answer:
      "If you intend to become an agent or a merchant and register with COOPMONI, having a terminal ID is essential. It enables you to carry out cash-out transactions. However, if you don’t plan to become an agent, you won’t need a terminal ID.",
  },
  {
    id: 6,
    question:
      "How does COOPMONI ensure compliance with regulatory requirements?",
    answer:
      "COOPMONI diligently adheres to all regulatory requirements, both domestically and internationally. It takes necessary steps to meet and retain compliance standards.",
  },
  {
    id: 7,
    question:
      "Is there customer support available for technical issues or inquiries?",
    answer:
      "Absolutely! COOPMONI offers robust customer support 24 hours a day, 7 days a week. If you encounter technical issues or have inquiries, they are ready to assist.",
  },
  {
    id: 8,
    question: "How does COOPMONI handle transaction reconciliation?",
    answer:
      "In the event of a transaction error, COOPMONI promptly ensures reconciliation upon claims and verification. This process helps rectify any discrepancies.",
  },
  {
    id: 9,
    question: "Can COOPMONI support multiple languages and currencies?",
    answer:
      "COOPMONI Application is only available in the English language and predominantly transacts currencies in naira hence it is a naira-based application.",
  },
  {
    id: 10,
    question:
      "How does COOPMONI address connectivity challenges in remote areas?",
    answer:
      "COOPMONI relies on the connection of the customer. In remote areas, we advise users to consider changing their service provider for better connectivity or use the alternative USSD channel.",
  },
  {
    id: 11,
    question:
      "Is there training provided for agents on how to use the software?",
    answer:
      "The application is easy to use, quick to understand, and very convenient so there is little or no need for training.",
  },
  {
    id: 12,
    question:
      "What measures are in place to prevent fraud and unauthorized access?",
    answer:
      "COOPMONI ensures there are security check measures put in place to prevent fraud and unauthorized access to the customers' database on the application.",
  },
  {
    id: 13,
    question: "How does COOPMONI ensure data privacy for customers and agents?",
    answer:
      "COOPMONI prioritizes the privacy and security of both its customers and agents through a comprehensive approach. Sensitive data transmitted between COOPMONI application and servers is encrypted using secure protocols and algorithms to prevent unauthorized access. Access controls, including role-based authentication mechanisms, ensure that only authorized personnel have access to customer and agent data. Regular security audits and assessments are conducted to identify and address potential vulnerabilities in systems and processes, ensuring compliance with data privacy regulations and industry best practices.",
  },
  {
    id: 14,
    question: "How does COOPMONI support regulatory changes over time?",
    answer:
      "COOPMONI stays up-to-date with regulatory changes by closely monitoring directives from regulatory bodies. Whenever there are updates or modifications, COOPMONI promptly takes necessary actions to ensure compliance.",
  },
  {
    id: 15,
    question:
      "Can COOPMONI software be customized to suit specific business requirements?",
    answer:
      "COOPMONI aims to develop an advanced mobile application that caters to the diverse needs of both urban and rural users. The focus is on revolutionizing financial management regardless of location or background. Additionally, COOPMONI educates users on best practices for safeguarding personal information and complies with relevant data privacy regulations (such as NDPR), demonstrating their commitment to user data privacy and security.",
  },
  {
    id: 16,
    question: "How secure is COOPMONI software?",
    answer:
      "The software is secure because COOPMONI has put in place system checks that help to validate and maintain the software application.",
    extra:
      "COOPMONI diligently adheres to all regulatory requirements, both domestically and internationally. It takes necessary steps to meet and retain compliance standards.",
  },
  {
    id: 17,
    question: "What services can be offered through the COOPMONI?",
    answer:
      "COOPMONI application provides the means for withdrawal/ transfer of funds, airtime recharge, payment of utility bills, and more services that are yet to be plugged in.",
    extra:
      "Withdrawal and transfer of funds: Users can withdraw cash and transfer money to other accounts.",
    extra_1: "Airtime recharge: Top-up mobile phone airtime conveniently.",
    extra_2:
      "Payment of utility bills: Pay bills such as electricity, water, and other utilities.",
  },
  {
    id: 18,
    question:
      "How does COOPMONI ensure data privacy for customers, merchants and agents?",
    answer:
      "COOPMONI places a strong emphasis on data privacy and security for both customers and agents. Here’s how:",
    extra:
      "Encryption: Sensitive data transmitted between the COOPMONI application and servers is encrypted using secure protocols and algorithms. This ensures that unauthorized parties cannot access or intercept the data during transmission.",
    extra_1:
      "Access Controls: Role-based authentication mechanisms are in place to control access. Only authorized personnel have the necessary permissions to view customer and agent data.",
    extra_2:
      "Security Audits: Regular security audits and assessments are conducted to identify and address potential vulnerabilities in systems and processes.",
    extra_3:
      "Compliance: COOPMONI diligently adheres to data privacy regulations and follows industry best practices to safeguard user information.",
  },
  // More questions...
];

export default function Faq() {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-2xl lg:text-4xl font-bold leading-10 tracking-tight text-gray-900 mb-5">
            Frequently asked questions
          </h2>
          <p>
            Got questions? We've got answers! Explore our frequently asked
            questions section to find information about our products, services,
            security measures, and more. If you can't find what you're looking
            for, feel free to reach out to our{" "}
            <Link
              to="mailto:support@coopvest.ng"
              className="font-semibold text-primary hover:primary/80"
            >
              customer support team
            </Link>{" "}
            for assistance.
          </p>
        </div>
        <div className="mt-20">
          <Accordion type="single" collapsible>
            {faqs.map((faq) => (
              <AccordionItem value={String(faq.id)} key={faq.id}>
                <AccordionTrigger className="text-base font-semibold leading-7 text-gray-900">
                  {faq.question}
                </AccordionTrigger>
                <AccordionContent>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {faq.answer}
                  </dd>
                  <div className="mt-2 space-y-2 text-base leading-7 text-gray-600">
                    <p>{faq.extra && faq.extra}</p>
                    <p>{faq.extra_1 && faq.extra_1}</p>
                    <p>{faq.extra_2 && faq.extra_2}</p>
                    <p>{faq.extra_3 && faq.extra_3}</p>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}
