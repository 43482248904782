const products: { title: string; description: any; href?: string }[] = [
  {
    title: "COOPMONI",
    description: {
      __html: `<p> <strong>COOPMONI</strong> (AGENCY BANKING), Bridging the Banking Gap for Low-Income Earners in Nigeria.</p>`,
    },
    href: "/coopmoni",
  },
  {
    title: "COOPURSE",
    description: {
      __html: `<p> <strong>COOPURSE</strong>, your Digital Wallet </p>`,
    },
    href: "/coopurse",
  },
  {
    title: "COOPZONE",
    description: {
      __html: `<p> <strong>COOPZONE</strong>, coming soon.</p>`,
    },
    href: "/coopzone",
  },
  {
    title: "LOANS",
    description: {
      __html: `<p> <strong>LOANS</strong>, coming soon.</p>`,
    },
    href: "/loans",
  },
];
export default products;
