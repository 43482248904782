import React from "react";
import { Boxes } from "../ui/background-boxes";
import depth from "../../assets/depth2.jpg";
import { mockup7 } from "../../assets/mockups";

type Props = {};

const Hero = (props: Props) => {
  return (
    <main className="relative isolate h-fit overflow-hidden items-center justify-center text-center lg:text-left px-5 clip-m pb-16 flex felx-col lg:flex-row gap-5">
      {/* <img
        src={depth}
        alt=""
        className="absolute top-0 left-0 w-full h-auto object-cover"
      /> */}
      <section
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <aside
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </section>
      <section className="mt-8">
        <Boxes />
        <h1 className="relative max-w-2xl mx-auto text-[48px] font-jakarta font-black">
          <span className="text-primary">COOPMONI</span> (AGENCY BANKING)
        </h1>
        <p className="relative text-muted-foreground">
          Empowering communities one transaction at a time.
        </p>
      </section>
      <section className="relative hidden lg:block">
        <div className="h-24 w-24  bg-[#FFDE82] flex justify-center items-center absolute top-12 left-0 z-10 rounded-full">
          <img src="/chart.png" alt="" />
        </div>
        {/* <div className="h-24 w-24 bg-[#11D064] absolute bottom-12 right-0 z-10 rounded-full"></div> */}
        <div className="relative mt-16 h-[400px] w-[400px] rounded-full overflow-hidden">
          {/* <div className="bg-[#11D064] relative mt-16 h-[400px] w-[400px] rounded-full overflow-hidden flex items-center justify-center"> */}
          <img
            src={mockup7}
            alt=""
            className="w-[600px] h-[600px] object-cover"
          />
          {/* </div> */}
        </div>
      </section>
    </main>
  );
};

export default Hero;
