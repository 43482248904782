import React from "react";
import { Link } from "react-router-dom";
import man from "../../assets/financial-empowerment.jpg";
import { BsArrowReturnRight } from "react-icons/bs";

type Props = {};

const CallToAction = (props: Props) => {
  return (
    <section className="flex flex-col lg:flex-row lg:items-center lg:justify-between overflow-hidden lg:h-[510px] bg-primary">
      <div className="relative px-4 pb-5 xl:pl-[80px]">
        <article className="max-w-[500px] z-30 mx-auto text-center lg:mx-0 lg:text-left">
          <h1 className="font-jakarta font-black text-[38px] xl:text-[42px] text-white capitalize">
            Unlock Financial Empowerment with Coopvest
          </h1>
          <p className="mt-4">
            Embark on a journey of financial empowerment with Coopvest Limited.
            Explore tailored solutions, cutting-edge innovation, and dedicated
            support. Click now to unlock your financial future.
          </p>
          <div className="mt-12 text-white font-jakarta uppercase hover:opacity-90 hover:text-[#034f36] hover:underline duration-300 ease-in-out">
            <Link to="/coopmoni">
              <div className="flex items-center gap-3">
                <>
                  <BsArrowReturnRight className="h-8 w-8" />
                </>

                <p className="hover:underline font-jakarta text-[20px] font-medium">
                  Learn More
                </p>
              </div>
            </Link>
          </div>
        </article>
      </div>
      <figure className="lg:w-[50%] lg:h-full max-w-[800px] mx-auto lg:mx-0">
        <img src={man} alt="" className=" w-full lg:h-full lg:object-cover" />
      </figure>
    </section>
  );
};

export default CallToAction;
