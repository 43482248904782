import React from "react";
import { BsArrowReturnRight } from "react-icons/bs";
import { Link } from "react-router-dom";

type Props = {};

const MoreDetails = (props: Props) => {
  return (
    <main className="container grid grid-cols-1 lg:grid-cols-2 gap-4 !mb-16">
      <aside className=" rounded-3xl text-center h-[500px] flex flex-col items-center justify-center">
        <h1 className="text-[48px] max-w-[500px] capitalize font-jakarta font-black mx-auto text-[#034f36]">
          digital financial services
        </h1>
        <p className="text-[24px] mt-6 max-w-[350px] mx-auto">
          From seamless payments to customized loans, we've got you covered
        </p>
      </aside>
      <aside className="bg-white rounded-3xl h-[500px] flex flex-col justify-between p-8">
        <article>
          <h1 className="text-[32px] capitalize font-jakarta font-black text-[#034f36]">
            Redefining agency banking
          </h1>
          <p className="text-base mt-6">
            From seamless payments to customized loans, we've got you covered.
            COOPMONI caters to Low-income earners aged 18 and above. It
            specifically addresses the financial needs of individuals who have
            limited access to formal banking services.
          </p>
        </article>
        <div>
          <Link to="/coopmoni">
            <div className="flex items-center gap-3">
              <>
                <BsArrowReturnRight className="h-8 w-8 text-primary" />
              </>
              <p className="text-primary hover:underline font-jakarta font-medium">
                COOPMONI
              </p>
            </div>
          </Link>
        </div>
      </aside>
      <aside className="bg-white rounded-3xl h-[500px] flex flex-col justify-between p-8">
        <article>
          <h1 className="text-[32px] capitalize font-jakarta font-black text-[#034f36]">
            designed for Cooperatives to streamline their financial operations
          </h1>
          <p className="text-base mt-6">
            As a comprehensive mobile application, COOPURSE empowers
            cooperatives, and other individual to manage their daily savings and
            track operational payments seamlessly.
          </p>
        </article>
        <div>
          <Link to="/coopurse">
            <div className="flex items-center gap-3">
              <>
                <BsArrowReturnRight className="h-8 w-8 text-primary" />
              </>

              <p className=" text-primary hover:underline font-jakarta font-medium">
                COOPURSE
              </p>
            </div>
          </Link>
        </div>
      </aside>
      <aside className="bg-white rounded-3xl h-[500px] flex flex-col justify-between p-8">
        <article>
          <h1 className="text-[32px] capitalize font-jakarta font-black text-[#034f36]">
            Loans, no matter who you are
          </h1>
          <p className="text-base mt-6">
            Swift Loans for Cooperatives, Entrepreneurs, and SMEs. Whether
            you’re a cooperative of petty traders, a solo retail marketer, or a
            corporate professional in need of fast financial solutions, we’ve
            got you covered. Contact us to discover our various loan options and
            terms.
          </p>
        </article>
        <div>
          <Link to="/loans">
            <div className="flex items-center gap-3">
              <>
                <BsArrowReturnRight className="h-8 w-8 text-primary" />
              </>
              <p className="text-primary hover:underline font-jakarta font-medium">
                LOANS
              </p>
            </div>
          </Link>
        </div>
      </aside>
    </main>
  );
};

export default MoreDetails;
