import { NavigationMenuLink } from "@radix-ui/react-navigation-menu";
import React from "react";
import { cn } from "../lib/utils";
import { navigationMenuTriggerStyle } from "./ui/navigation-menu";
import { Link } from "react-router-dom";

type Props = {
  title: String;
  description: { __html: string | TrustedHTML } | undefined;
  href?: string;
};
const ListItem = ({ title, description, href }: Props) => {
  return (
    <div className="">
      {!!href ? (
        <Link to={href}>
          <div>
            <div className="text-base font-medium tracking-wide font-jakarta ">
              {title}
            </div>
            <p
              className=" text-sm leading-snug text-muted-foreground text-wrap h-fit"
              dangerouslySetInnerHTML={description}
            />
          </div>
        </Link>
      ) : (
        <div>
          <div className="text-base font-medium tracking-wide font-jakarta ">
            {title}
          </div>
          <p
            className=" text-sm leading-snug text-muted-foreground text-wrap h-fit"
            dangerouslySetInnerHTML={description}
          />
        </div>
      )}
    </div>
  );
};

export default ListItem;
