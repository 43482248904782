import React, { useRef } from "react";
import { BentoGrid, BentoGridItem } from "../ui/bento-grid";
import { mockup2, mockup4, mockup5 } from "../../assets/mockups";

type Props = {};

const data = [
  {
    icon: "/money-transfer.png",
    title: "Transfer",
    description:
      "Coopurse provides this service to any customer willing to transfer funds to any bank in Nigeria.",
  },
  {
    icon: "/bill.png",
    title: "Bills payment",
    description:
      "A service for making bill-related payments and subscriptions for DSTV, GOTV, IBEDC, NECO, WAEC, etc.",
  },
  {
    icon: "/shield.png",
    title: "Airtime",
    description: "Airtime vending on popular mobile networks in Nigeria.",
  },
  {
    icon: "/loan.png",
    title: "Loans",
    description:
      "Micro loans are provided to worthy customers for urgent use to be paid back between 15 days and 3 months depending on the amount (minimum of 5,000 and a maximum of 50,000).",
  },

  {
    icon: "/investment.png",
    title: "Investments",
    description:
      "Like the savings service, we provide an investment platform with higher returns on investment.",
  },
  {
    icon: "/piggy-bank.png",
    title: "Savings",
    description:
      "This service provides savings platform for customers with competitive interest rate.",
  },
];

const Coop = (props: Props) => {
  return (
    <main>
      <section className="px-5 xl:px-[80px]">
        <article>
          <h1 className="text-[26px] lg:text-[48px] text-center font-jakarta font-black">
            What is <span className="text-primary">COOPURSE</span>?
          </h1>
          <p className="max-w-6xl mx-auto md:text-center text-left">
            Coopurse stands as a cutting-edge digital banking solution designed
            to enrich the banking journey for smartphone users. Through our
            intuitive mobile application, users can seamlessly navigate a
            comprehensive suite of services, encompassing everything from
            hassle-free onboarding and account opening to streamlined transfers,
            convenient bills payment, robust savings options, tailored
            investment opportunities, and accessible loan facilities.
          </p>
        </article>
        <section>
          <article className="relative w-fit mx-auto">
            <h1 className="font-jakarta font-black text-[32px] text-center text-[#034f36] uppercase p-6">
              Key features
            </h1>
            <img
              src="/line.svg"
              alt=""
              className="absolute -right-11 bottom-1 h-auto w-[150px]"
            />
          </article>
          <aside className="flex flex-wrap items-center justify-center gap-10">
            <div className="w-full max-w-[400px] h-[250px] overflow-hidden rounded-2xl bg-primary/60 p-5">
              <p className="h-8 w-8 flex justify-center items-center rounded-full bg-[#034f36] text-white">
                1
              </p>
              <h1 className="font-jakarta text-[20px] font-semibold text-[#034f36] mt-3">
                Onboarding & Account Opening
              </h1>
              <p className="text-[15px] font-light mt-5">
                A user-friendly interface for quick and efficient member
                registration and account creation.
              </p>
              {/* <figure className="w-full relative">
                <img
                  src={mockup4}
                  alt=""
                  className="absolute top-0 left-0 w-[400px] h-auto object-cover"
                />
              </figure> */}
            </div>
            <div className="w-full max-w-[400px] h-[250px] overflow-hidden rounded-2xl bg-primary/60 p-5">
              <p className="h-8 w-8 flex justify-center items-center rounded-full bg-[#034f36] text-white">
                2
              </p>
              <h1 className="font-jakarta text-[20px] font-semibold text-[#034f36] mt-3">
                Payments
              </h1>
              <p className="text-[15px] font-light mt-5">
                Facilitate smooth and secure transactions, including
                inter-member transfers and external payments.
              </p>
              {/* <figure className="w-full relative">
                <img
                  src={mockup2}
                  alt=""
                  className="absolute top-0 left-0 w-[400px] h-auto object-cover"
                />
              </figure> */}
            </div>
            <div className="w-full max-w-[400px] h-[250px] overflow-hidden rounded-2xl bg-primary/60 p-5">
              <p className="h-8 w-8 flex justify-center items-center rounded-full bg-[#034f36] text-white">
                3
              </p>
              <h1 className="font-jakarta text-[20px] font-semibold text-[#034f36] mt-3">
                Loans
              </h1>
              <p className="text-[15px] font-light mt-5">
                Unlock financial growth with Coopurse's loans. Tailored for all,
                our digital platform ensures seamless access to support your
                dreams. Join us in revolutionizing financial inclusion for every
                Nigerian.
              </p>
              {/* <figure className="w-full relative">
                <img
                  src={mockup5}
                  alt=""
                  className="absolute top-0 left-0 w-[400px] h-auto object-cover"
                />
              </figure> */}
            </div>
          </aside>
        </section>
        <BentoGrid className="max-w-4xl mx-auto mt-28">
          {data.map((item, i) => (
            <BentoGridItem
              index={i}
              title={item.title}
              description={item.description}
              icon={item.icon}
              className={i === 3 || i === 6 ? "md:col-span-2" : ""}
            />
          ))}
        </BentoGrid>
      </section>
    </main>
  );
};

export default Coop;
