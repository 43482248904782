import About from "../pages/about";
import Agent from "../pages/agent";
import Coopmoni from "../pages/coopmoni";
import Cooporatives from "../pages/cooporatives";
import Coopurse from "../pages/coopurse";
import CoopZone from "../pages/coopzone";
import Faq from "../pages/faq";
import Home from "../pages/home";
import Loans from "../pages/loans";
import MerchantPage from "../pages/merchant";
import Privacy from "../pages/privacy";
import Team from "../pages/team";

export const routes = [
  {
    path: "/",
    label: "Home",
    component: <Home />,
  },
  {
    path: "/about",
    label: "About",
    component: <About />,
  },
  {
    path: "/our-team",
    label: "Team",
    component: <Team />,
  },
  {
    path: "/faq",
    label: "Faq",
    component: <Faq />,
  },
  {
    path: "/coopmoni",
    label: "Coopmoni",
    component: <Coopmoni />,
  },
  {
    path: "/coopzone",
    label: "Coozone",
    component: <CoopZone />,
  },
  {
    path: "/coopurse",
    label: "Coopurse",
    component: <Coopurse />,
  },
  {
    path: "/become-an-agent",
    label: "Become An Agent",
    component: <Agent />,
  },
  {
    path: "/become-a-merchant",
    label: "Become A Merchant",
    component: <MerchantPage />,
  },
  {
    path: "/register-cooporative",
    label: "Register Cooporative",
    component: <Cooporatives />,
  },
  {
    path: "/loans",
    label: "Loans",
    component: <Loans />,
  },
  {
    path: "/privacy",
    label: "Privacy",
    component: <Privacy />,
  },
];
