import React, { useEffect } from "react";
import Hero from "../../components/coopurse/Hero";
import Coop from "../../components/coopurse/coop";
import DownloadApp from "../../components/home/DownloadApp";

type Props = {};

const Coopurse = (props: Props) => {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <main>
      <section>
        <Hero />
      </section>
      <section className="pt-32">
        <Coop />
      </section>
      <section className="bg-primary mt-28">
        <DownloadApp />
      </section>
    </main>
  );
};

export default Coopurse;
