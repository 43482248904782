import React from "react";
import { Button } from "../ui/button";
import { HashLink } from "react-router-hash-link";
import member7 from "../../assets/dig.png";
import { phone } from "../../assets/mockups";
import { BackgroundGradientAnimation } from "../ui/background-gradient-animation";
import { WavyBackground } from "../ui/wavy-background";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

type Props = {};

const Hero = (props: Props) => {
  const navigate = useNavigate();
  return (
    <main className="isolate lg:pt-14 flex items-center min-h-[calc(100dvh-107px) lg:min-h-[calc(100dvh-123px)]">
      <section
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
        aria-hidden="true"
      >
        <aside
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </section>
      <div className="relative px-5 lg:px-6 xl:px-20 w-full">
        <section className="lg:flex lg:gap-4 lg:items-center lg:justify-center relative z-10 w-full mx-auto lg:mx-0">
          <aside className="mx-auto max-w-2xl lg:mx-0">
            <div className="">
              <h1 className="text-4xl !leading-[1.3] text-gray-900 sm:text-6xl font-jakarta font-black text-center lg:text-left">
                Your one-stop solution to digital{" "}
                <span className="text-primary">financial services</span>
              </h1>
              <p className="mt-6 leading-8 text-gray-600 text-center text-sm lg:text-left">
                We understand the unique needs of Cooperatives, Individuals, and
                Small Businesses, from seamless payments to customized loans,
                we’ve got you covered
              </p>
              <div className="mt-10 flex items-center gap-x-6 w-fit mx-auto lg:mx-0">
                <Link to="/coopmoni">
                  <Button className="bg-[#034f36] rounded-md text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                    Get started
                  </Button>
                </Link>
                <Button
                  onClick={() => navigate("/about")}
                  variant="outline"
                  className="text-sm font-semibold leading-6 text-gray-900 "
                >
                  Learn more
                </Button>
              </div>
            </div>
          </aside>
          <aside className="hidden lg:block relative z-20 h-[500px] w-[500px]">
            <img
              src={phone}
              alt=""
              className="h-[500px] object-contain w-[500px] absolute top-0 left-0"
            />
            <div className="absolute top-2 right-2 z-10 p-2 px-9 bg-white border border-gray-300 rounded-full w-fit animate-bounce animate-infinite">
              <h1 className="text-[#034f36] text-sm font-jakarta font-medium">
                COOPMONI
              </h1>
              <p className="pl-6 text-[10px]">Agency banking.</p>
            </div>
            <div className="absolute top-0 bottom-0 my-auto z-10 right-20 h-fit p-2 px-9 bg-white border border-gray-300 rounded-full w-fit animate-bounce animate-infinite">
              <h1 className="text-[#034f36] text-sm font-jakarta font-medium">
                COOPURSE
              </h1>
              <p className="pl-6 text-[10px]">Manage your finances</p>
            </div>
            <div className="absolute bottom-3 left-0 z-10 p-2 px-9 bg-white border border-gray-300 rounded-full w-fit animate-bounce animate-infinite">
              <h1 className="text-[#034f36] text-sm font-jakarta font-medium">
                LOANS
              </h1>
              <p className="pl-6 text-[10px]">Access loans from anywhere</p>
            </div>
          </aside>
        </section>
        <section className="max-w-4xl w-full md:shadow-2xl mx-auto p-8 md:p-16 rounded-2xl flex flex-col gap-5 mt-16 bg-white shadow-lg md:flex-row justify-center">
          <div className="flex gap-3 items-center px-5 border-b-2 md:border-b-0 py-5 md:border-r-2 border-[#034f36]">
            <img src="/shield.png" alt="" className="w-9 h-9" />
            <h1>Security</h1>
          </div>
          <div className="flex gap-3 items-center px-5 border-b-2 md:border-b-0 py-5 md:border-r-2 border-[#034f36]">
            <img src="/bill.png" alt="" className="w-9  h-9" />
            <h1>Process Payments</h1>
          </div>
          <div className="flex gap-3 items-center px-5 py-5">
            <img src="/piggy-bank.png" alt="" className="w-9 h-9" />
            <h1>Savings Management</h1>
          </div>
        </section>
      </div>
      {/* <aside
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </aside> */}
    </main>
  );
};

export default Hero;
