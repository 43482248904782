import React, { useEffect } from "react";
import Hero from "../../components/coopmoni/Hero";
import Coop from "../../components/coopmoni/coop";
import DownloadApp from "../../components/home/DownloadApp";

type Props = {};

const Coopmoni = (props: Props) => {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  });
  return (
    <main>
      <section>
        <Hero />
      </section>
      <section className="pt-32">
        <Coop />
      </section>
      <section className="bg-green-400 mt-28">
        <DownloadApp />
      </section>
    </main>
  );
};

export default Coopmoni;
