import React from "react";
import { Link } from "react-router-dom";
import phone from "../../assets/phone-upp.png";
import man from "../../assets/man-up.png";

type Props = {};

const words = `Your one stop solution to digital financial services`;

const DownloadApp = (props: Props) => {
  return (
    <main className="relative overflow-hidden container mx-auto">
      <section className="flex flex-col lg:flex-row lg:items-center lg:justify-between overflow-hidden lg:h-[510px]">
        <div className="relative px-4 pb-5 xl:pl-[80px] pt-7 lg:pt-0">
          <article className="max-w-[350px] xl:max-w-[500px] z-30 text-center mx-auto lg:text-left lg:mx-0">
            <p className="font-jakarta font-black text-[38px] xl:text-[42px] text-white capitalize">
              Your one stop solution to digital financial services
            </p>
            <p className="mt-4">
              Ready to revolutionize your cooperative's financial experience?
              Join us now and unlock a world of possibilities.
            </p>
            <p className="text-white mt-3">
              You can download{" "}
              <span className="text text-chateau-green-800 uppercase">
                Coopmoni
              </span>{" "}
              app on google play store
            </p>
            <Link
              to="https://play.google.com/store/apps/details?id=ng.coopvest.coopmoni"
              target="__blank"
              referrerPolicy="no-referrer"
              className="w-fit mx-auto lg:mx-0"
            >
              <img src="/google.svg " alt="" className="mt-6 mx-auto lg:mx-0" />
            </Link>
          </article>
        </div>
        <figure className="flex lg:h-full lg:pr-4 lg:pb-0 mx-auto lg:mx-0">
          <img
            src={man}
            alt=""
            className="object-cover lg:h-full w-full rounded-lg lg:rounded-none"
          />
          <img src={phone} alt="" className="object-contain lg:h-full w-full" />
        </figure>
      </section>
    </main>
  );
};

export default DownloadApp;
